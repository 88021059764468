<template>
  <div>
    <NavBar
      style="max-height: 50px;margin-bottom: 10px;"
      :username="currentUserName"
      :pageName="pageName"
      class="topNavBar"
    ></NavBar>
    <v-app>
      <div style="background-color: #f5faff;">
        <v-row cols="12">
        <v-col cols="2">
        <SideBar style="max-height: 50px;" :pageName="pageName" class="sideBar"></SideBar>
        </v-col>

          <v-col cols="10" style="overflow:scroll; height:95vh">
            <div style="margin: 40px">
              <v-row
                style="
                  margin-left: 10px;
                  display: flex;
                  justify-content: space-between;"
                  class="headingClass"

              >
                <span style="font-weight: 800">
                  <span style="font-weight: 800; padding-left: 5px;  margin-top: 10px;font-size: 12pt; text-transform: uppercase;letter-spacing: 1px;"
                    >All Patient Information</span>
                </span>
                <span>
                  <div style="display: flex; width: 95%; margin-bottom: 8%">
                    <div style="margin-left: 2%">
                      <v-text-field
                        v-model="name"
                        placeholder="Search"
                        dense
                        solo
                        style="width: 90%"
                        ><template v-slot:append>
                          <v-btn icon>
                            <img
                              style="width: 25px; height: 25px"
                              src="https://s3iconimages.mymedicine.com.mm/search.svg"
                            />
                          </v-btn>
                        </template>
                      </v-text-field>
                    </div>
                  </div>
                </span>
              </v-row>
              <v-row
              class="mobileHeadingClass">
              <span style="font-weight: 800; padding-left: 5px;  margin-top: 10px;font-size: 12pt; text-transform: uppercase;letter-spacing: 1px;">
                    All Patient Information
              </span>
              <div class="d-flex mt-3">
                <div style="width:45%;">
                  <p style="margin-top: 6px; font-weight: 500; font-size: 16px;">Search</p>
                </div>
                <div>
                  <v-text-field
                    v-model="name"
                    placeholder="Search"
                    dense
                    solo
                    ><template v-slot:append>
                      <v-btn icon>
                        <img
                          style="width: 25px; height: 25px"
                          src="https://s3iconimages.mymedicine.com.mm/search.svg"
                        />
                      </v-btn>
                    </template>
                  </v-text-field>
                </div>
              </div>
              </v-row>

              <div v-if="!isLoadingData" style="margin-top:1%;">
                <v-data-table
                  :headers="headers"
                  :items="patient_list"
                  class="viewAllPatientsTable"
                  :search="name"
                  >
                <!-- changeStatusofCorporate -->
                  <template v-slot:item.action="{item}" >
                    <v-btn class="mx-2" icon @click="viewPatientDetails(item)">
                      <img src="https://s3iconimages.mymedicine.com.mm/show_pwd.svg" style="height: 24px; width: 24px;"/>
                    </v-btn>
                  </template>
                </v-data-table>
              </div>
              <div v-else>
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
              <div v-if="pagination_list > 1">
                   <v-pagination
                        circle
                        v-model="page"
                        :length="pagination_list"
                        :total-visible="3"
                        @input="findPage(page)"
                   ></v-pagination>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-app>
    <bottoNavBar :pageName = 'bottomNavBarValue' class="bottomNavBar"></bottoNavBar>
  </div>
</template>


<script>
import axios from "axios";
import NavBar from "../../../views/navbar.vue";
import SideBar from "../../../views/sideBar.vue";
import { axios_auth_instance_doctor } from '../../../utils/axios_utils';
import { handleError } from '../../../utils/utils';

export default {
  name: "allPatient",

  data() {
    return {
      email:'',
      patient_list: [],
      particular_list: "",
      bottomNavBarValue: 'PatientList',
      headers : [
          {
            text: '',
            value: '',
            width: "20px",
            sortable:false
          },
          {
            text: 'Patient Name',
            value: 'customer_name',sortable:false

          },
          { text: 'Gender', value: 'customer_gender',sortable:false },
          { text: 'Age', value: 'age',sortable:false },
          { text: 'Action',sortable:false,value: 'action', width: "10%" },

      ],
      user_id: "",
      currentUser: "",
      search_type: "",
      currentUserName: "",
      page: 1,
      limit: "",
      pageName: "",
      isLoadingData: true,
      nrc_number: "",
      name: "",
      age : "",
      phone_number: "",
      pagination_list: [],
      items: ["Phone Number","Name"],
      mobileNumberRules: [(v) => v > 0 || "Mobile number cannot be negative",
                          (v) => !!v || "Mobile number is required"],
    };
  },
  mounted() {
    this.pageName = "ViewAllPatientDoc";
  document.title = "View All Patient"
    this.currentUser = this.$cookies.get("doctorToken");
    if (!this.currentUser) {
      this.$router.push({
        name: "DoctorLogin",
      });
    } else {
      var data = {
        token : this.currentUser,
        typeOfUser : "DOCTOR"
      };
      axios_auth_instance_doctor.post("/doctorViewAllPatientsPagev2", data)
      .then((successResponse) => {
        //console.log(successResponse);
        this.currentUserName = successResponse.data.doctor_data.doctor_name;
        this.user_id = successResponse.data.doctor_data._id;
        var patient_data_list = successResponse.data.patient_data;
        if(patient_data_list.length > 0){
          for(var i = 0; i < patient_data_list.length; i++){
            if(patient_data_list[i] != null)
            {
               //console.log(patient_data_list[i].email);
              if(patient_data_list[i].customer_name)
              {
                patient_data_list[i].age = this.get_age(patient_data_list[i].customer_dob);
                patient_data_list[i].customer_name = patient_data_list[i].customer_name;
                patient_data_list[i].customer_gender = patient_data_list[i].customer_gender;
              }
              else
              {
                patient_data_list[i].age = this.get_age(patient_data_list[i].dependent_dob);
                patient_data_list[i].customer_name = patient_data_list[i].dependent_name;
                patient_data_list[i].customer_gender = patient_data_list[i].dependent_gender;
              }
              this.patient_list.push(patient_data_list[i]);
            }
          }

        }
        this.isLoadingData = false;
      })
      .catch((errorResponse) => {
        this.isLoadingData = false;
        handleError(errorResponse, this.$router, 'doctorViewAllPatientsPagev2', 'DoctorLogin', 'doctorToken');
      })
    }
  },
  components: {
    NavBar,
    SideBar,
    bottoNavBar: () => import('../../../components/docBottomNavBar.vue')
  },
  methods: {
    get_age(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      if(age < 5)
      {
        let year_str = "", month_str = "";
        if(m < 0)
          m = 12 + m;
        year_str = age <= 1 ? " year " : " years ";
        month_str = m <= 1 ? " month " : " months ";
        if(age > 0)
          age = age + year_str + m + month_str;
        else
          age = m + month_str;
      }
      return age;
    },
    // getEmail(user_email){
    // var avg, splitted, part1, part2;
    // splitted = user_email.split("@");
    // console.log(splitted);
    // part1 = splitted[0];
    // console.log(part1);
    // avg = part1.length / 2;
    // console.log(avg);
    // part1 = part1.substring(0, (part1.length - avg));
    // console.log(part1);
    // part2 = splitted[1];
    // console.log(part2);
    // return part1 + "...@" + part2;
    // },
    gotoLoginPage() {
      var deleteData = {
        idToken: this.currentUser,
      };
      axios
        .post(process.env.VUE_APP_BACKEND_URL + "/deleteAdminToken", deleteData)
        .then((deleteResponse) => {
          this.$cookies.remove("token");
        })
        .catch((error) => {
        });
      localStorage.clear();
      this.$router.push({
        name: "DoctorLogin",
      });
    },
    viewPatientDetails(patient) {
      this.$router.push({
        path: "doctorViewPatientDetails/" + patient._id,
      });
    },

    filterPatient() {
      this.isLoadingData = true;
      this.patient_list = "";
      var getPatientData = {
        token: this.currentUser,
        typeOfUser: "DOCTOR",
        doctorId : this.user_id,
        phoneNumber: this.phone_number,
        name: this.name
      };
      axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/getPatientsOfParticularDoctor",
          getPatientData
        )
        .then((getparticularListResponse) => {
          this.patient_list = getparticularListResponse.data.data;
          if(this.patient_list.length > 0)
          {
            for(var i=0;i<this.patient_list.length;i++){
              if(this.patient_list[i].customer_dob)
               this.patient_list[i].age = this.get_age(this.patient_list[i].customer_dob);
              else
               this.patient_list[i].age = this.get_age(this.patient_list[i].dependent_dob);
            }
          }
          else if(this.patient_list.length === undefined)
          {
            this.patient_list.age = this.patient_list.hasOwnProperty('customer_dob') ? this.get_age(this.patient_list.customer_dob) : this.get_age(this.patient_list.dependent_dob);
            this.patient_list = [this.patient_list];
          }
          this.pagination_list = getparticularListResponse.data.data.pages;
          this.isLoadingData = false;
        })
        .catch((getparticularListError) => {
          if (getparticularListError.response.status == 401) {
            window.alert("Sorry !! You are unauthorized !!");
            this.$router.push({
              name: "DoctorLogin",
            });
          } else {
            window.alert("Something went wrong...");
          }
        });
    },
    findPage(pageNum) {
      var getPageData = {
        token: this.currentUser,
        typeOfUser: "DOCTOR",
        page: pageNum,
        limit: 7,
        NRC: this.nrc_number,
        phoneNumber: this.phone_number,
      };
      axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/readAllCustomers",
          getPageData
        )
        .then((getPatientListResponse) => {
          this.patient_list = getPatientListResponse.data.data.docs;
        })
        .catch((getPatientListError) => {
          if (getPatientListError.response.status == 401) {
            window.alert("Sorry !! You are unauthorized !!");
            this.$router.push({
              name: "DoctorLogin",
            });
          } else {
            window.alert("Something went wrong...");
          }
        });
    },
  },
};
</script>

<style scoped>
th {
  border: 0px;
}
td {
  padding: 5px;
  font-size: 10pt;
  font-weight: 500;
}
th {
  background-color: #1467bf;
  color: white;
  border: 0px;
  font-size: 8pt;
  font-weight: 500;
  margin-left: 10%;
  position: sticky;
  top: 0;
  z-index: 10;
  height: 40px;
}
tr {
  border-bottom: #d4efff 1px solid !important;
}
.tableClass {
  border-spacing: 0px;
  width: 70%;
  border-collapse: collapse;
  z-index: 0;
  background-color: white;
  margin-left: 10px;
  height: 30px;
}
table > tr > td:last-child {
  white-space: nowrap;
}
.btnClass {
  background-color: transparent;
  margin: 2px;
}
/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.activeClass {
  color: white !important;
  background: blue;
  margin: 0px 3px !important;
  box-shadow: 0px 0px 28px 0px #1366be4d !important;
}
.defaultClass {
  color: black !important;
  background: white;
  margin: 0px 3px !important;
  box-shadow: 0px 0px 28px 0px #1366be4d !important;
}
.bottomNavBar{
    display: none;
  }
.mobileHeadingClass{
  display: none !important;
}
.headingClass{
  display: flex !important;
}
@media (min-width: 320px) and (max-width: 1024px){
.bottomNavBar{
  display: block;
  position: fixed;
  width: 100%;
  bottom: 0px;
}
.topNavBar,.sideBar {
  display: none !important;
}
.col-2, .col-10{
  flex: unset !important;
  max-width: unset !important;
}
.col-2{
  padding: unset !important;
}
.mobileHeadingClass{
  display: block !important;
  text-align: left;
  margin-bottom: 8px;
}
.headingClass{
  display: none !important;
}
}
</style>

<style>
  .viewAllPatientsTable .v-data-table-header th{
    background: #1467bf !important;
    color: white !important;
    font-size: 8pt !important;
    font-weight: 500 !important;
    padding-left:5px !important;
  }
  .viewAllPatientsTable tbody td{
    font-size: 8pt !important;
    font-weight: 700 !important;
    padding-left:5px !important;
  }
 </style>